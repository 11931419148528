import { Fragment, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function NotFound(props) {
    const [t] = useTranslation();
    useEffect(() => { document.title = `data_unknown - ${t("Title.NotFound")}` }, [t]);

    return (
        <Fragment>
            <Grid item><Typography variant="h1">404</Typography></Grid>
            <Grid item><Typography variant="h6">{t("NotFound")}</Typography></Grid>
            {props.location.pathname.includes('gay') && <Grid item><Typography variant="h9" color="error">No, only you!</Typography></Grid>}
        </Fragment>
    );
}

export default NotFound;