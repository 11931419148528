import { Fragment } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles((theme) => ({
    widget: {
        width: 100 + '%',
        minHeight: 300 + 'px',
        resize: 'vertical',
        border: '1px transparent solid',
        outline: 0
    },
    serverImg: {
        backgroundColor: theme.palette.grey[900],
        padding: 3 + 'px',
        border: '1px solid ' + theme.palette.primary.dark,
        borderRadius: 3
    }
}));

function DiscordServer(props) {
    const classes = useStyles();
    const t = useTranslation()[0];
    const { guildId, style, invite } = props;
    return (
        <Fragment>
            <Grid item><h2>{t("DiscordServer")}</h2></Grid>
            <Grid item>
                <a href={`https://discord.gg/${invite}`}>
                    <LazyLoadImage
                        className={classes.serverImg}
                        src={`https://discordapp.com/api/guilds/${guildId}/widget.png?style=${style}`}
                        alt={`https://discord.gg/${invite}`} />
                </a>
            </Grid>
        </Fragment>
    );
}

DiscordServer.propTypes = {
    guildId: PropTypes.string.isRequired,
    style: PropTypes.string.isRequired,
    invite: PropTypes.string.isRequired
}

export default DiscordServer;