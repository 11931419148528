import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider, CssBaseline, Backdrop, CircularProgress } from '@material-ui/core';
import { cyan, lime } from '@material-ui/core/colors';

// import i18n (needs to be bundled ;)) 
import './i18n';
import { BrowserRouter } from 'react-router-dom';

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: cyan,
    secondary: lime
  },
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Backdrop open={true}><CircularProgress color="primary"/></Backdrop>}>
        <App />
      </Suspense>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
