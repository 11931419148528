import { Fragment, useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import UnturnedServers from './UnturnedServers';
import TeamSpeakServer from './TeamSpeakServer';
import DiscordServer from './DiscordServer';
import { useTranslation } from 'react-i18next';

function Home() {
    let [loaded, setLoaded] = useState(false);
    let [servers, setServers] = useState(null);
    const [t, i18n] = useTranslation();
    useEffect(() => {
        fetch("/servers.json").then(res => res.json())
            .then(
                res => {
                    setServers(res);
                    setLoaded(true)
                },
                err => {
                    console.error('Failed to load server list!', err);
                    setLoaded(true);
                });
        document.title = `data_unknown - ${t("Title.Home")}`
    }, [i18n.language, t]);

    return (
        <Fragment>
            {!loaded && (<Grid item><CircularProgress color="primary" /></Grid>)}
            {(loaded && servers) && (
                <Fragment>
                    {servers.teamspeak && <TeamSpeakServer serverId={servers.teamspeak.serverId} link={servers.teamspeak.link} />}
                    {servers.discord && <DiscordServer guildId={servers.discord.guildId} invite={servers.discord.invite} style={servers.discord.style} />}
                    {servers.unturned && <UnturnedServers servers={servers.unturned} />}
                </Fragment>
            )}
            {(loaded && servers.length < 1) &&(
                <Fragment>
                    <Typography>{t("Common.NoServers")}</Typography>
                </Fragment>
            )}
        </Fragment>
    );
}

export default Home;
