import { Fragment } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles((theme) => ({
    serverImg: {
        backgroundColor: theme.palette.grey[900],
        padding: 3 + 'px',
        border: '1px solid ' + theme.palette.primary.dark,
        borderRadius: 3
    }
}));

const imgSizes = [
    [600, 100, 'banner'],
    [728, 90, 'leaderboard'],
    [468, 60, 'regular-banner'],
    [234, 60, 'half-banner']
];

function UnturnedServers(props) {
    const classes = useStyles();
    const t = useTranslation()[0];

    const leaderboard = useMediaQuery('(min-width: 950px)');
    const banner = useMediaQuery('(min-width: 780px)');
    const regularBanner = useMediaQuery('(min-width: 610px)');
    const bannerType = (leaderboard ? 1 : (banner ? 0 : (regularBanner ? 2 : 3)));

    return (
        <Fragment>
            <Grid item><h2>{t("UnturnedServers")}</h2></Grid>
            {props.servers.map(serverId => (
                <Grid item key={serverId}>
                    <a href={`https://unturned-servers.net/server/${serverId}`}>
                        <LazyLoadImage
                            className={classes.serverImg}
                            width={imgSizes[bannerType][0]}
                            height={imgSizes[bannerType][1]}
                            src={`https://unturned-servers.net/server/${serverId}/banners/${imgSizes[bannerType][2]}-${props.pictureId || 1}.png`}
                            alt={`https://unturned-servers.net/server/${serverId}`} />
                    </a>
                </Grid>))}
        </Fragment>);
}

UnturnedServers.propTypes = {
    servers: PropTypes.arrayOf(PropTypes.number).isRequired,
    pictureId: PropTypes.number
}

export default UnturnedServers;