import logo from './DtULogo.192x192.png';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, makeStyles, ButtonGroup, Button, Menu, MenuItem, Divider } from '@material-ui/core';
import { Switch, Route, useHistory } from "react-router-dom";
import { Home as HomeIcon, FormatAlignCenter as FormatAlignCenterIcon, /*Info as InfoIcon,*/ Language as LanguageIcon } from '@material-ui/icons';
import Home from './components/Home';
import Rules from './components/Rules';
import NotFound from './components/NotFound';
import { Fragment, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  lngBtn: {
    margin: 5 + 'px',
    position: 'absolute'
  },
  flexRoot: {
    flexGrow: 1,
  },
  root: {
    minHeight: 100 + 'vh'
  },
  paper: {
    backgroundColor: '#333'
  },
  container: {
    padding: 10 + 'px',
    textAlign: 'center',
    maxWidth: 80 + 'vw',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 100 + 'vw'
    }
  },
  linkBtn: {
    textDecoration: 'none'
  },
  divider: {
    margin: 10 + 'px'
  }
}));

function App() {
  const [t, i18n] = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [lngBtnAnchor, setLngBtnAnchor] = useState(null);

  function chngLng(lng) {
    i18n.changeLanguage(lng);
    setLngBtnAnchor(null)
  }

  return (
    <Fragment>
      <Button className={classes.lngBtn} startIcon={<LanguageIcon />} onClick={(e) => setLngBtnAnchor(e.currentTarget)}>{t("Language")}</Button>
      <Menu anchorEl={lngBtnAnchor} keepMounted open={Boolean(lngBtnAnchor)} onClose={() => setLngBtnAnchor(null)}>
        <MenuItem onClick={() => chngLng('en-GB')}>{t("Languages.English")}</MenuItem>
        <MenuItem onClick={() => chngLng('hu-HU')}>{t("Languages.Hungarian")}</MenuItem>
      </Menu>
      <div className={classes.flexRoot}>
        <Grid container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.root}>
          <Grid item>
            <Paper className={classes.paper}>
              <Grid container direction="column" className={classes.container}>
                <Grid item><img src={logo} alt="" /></Grid>
                <Grid item><h1>data_unknown</h1></Grid>
                <Grid item>
                  <ButtonGroup variant="text" color="primary">
                    <Button color="primary" onClick={() => history.push("/")} startIcon={<HomeIcon />}>{t("HomePage")}</Button>
                    <Button color="primary" onClick={() => history.push("/rules")} startIcon={<FormatAlignCenterIcon />}>{t("Rules")}</Button>
                    {/* <Button color="primary" onClick={() => history.push("/about")} startIcon={<InfoIcon />}>{t("About")}</Button> */}
                  </ButtonGroup>
                </Grid>
                <Grid item><Divider className={classes.divider} /></Grid>
                <Switch>
                  <Route exact path="/rules" component={Rules} />
                  <Route exact path="/" component={Home} />
                  <Route component={NotFound} />
                </Switch>
                <Grid item><Divider className={classes.divider} /></Grid>
                <Grid item><p>data_unknown v0.4.0 - &copy; {new Date().getFullYear()}</p></Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}

export default App;
