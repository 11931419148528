import { Grid, CircularProgress, Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown/with-html';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        textAlign: 'left',
        fontSize: 18 + 'px',
        color: 'rgb(210, 210, 210)'
    },
    list: {
        listStyle: 'none',
        padding: .5 + 'rem'
    },
    listItem: {
        margin: .5 + 'em'
    }
}));

function Rules() {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    let [loaded, setLoaded] = useState(false);
    let [rules, setRules] = useState();


    useEffect(() => {
        fetch(`/locales/${i18n.language}/rules.json`).then(res => res.json())
            .then(
                res => {
                    setRules(res);
                    setLoaded(true)
                },
                err => {
                    console.error('Failed to load the rules!', err);
                    setLoaded(true);
                });
        document.title = `data_unknown - ${t("Title.Rules")}`
    }, [i18n.language, t]);

    return (
        <Fragment>
            {!loaded && (<Grid item><CircularProgress color="primary" /></Grid>)}
            {rules && (
                <Grid item>
                    {rules.map((ruleSet, i) => (
                        <Accordion key={ruleSet + i}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {ruleSet.Title && <ReactMarkdown className={classes.heading}>{ruleSet.Title}</ReactMarkdown>}
                                {ruleSet.SubTitle && <ReactMarkdown className={classes.secondaryHeading}>{ruleSet.SubTitle}</ReactMarkdown>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.details}>
                                    <ReactMarkdown allowDangerousHtml>
                                        {(ruleSet.Rules ? `<ul class="${classes.list}">${ruleSet.Rules.map((rule, j) => `<li id="${i + 1}.${j + 1}" class="${classes.listItem}"><b>§${i + 1}.${j + 1}</b> - ${rule}</li>`).join('')}</ul>` : '')}
                                    </ReactMarkdown>
                                    <ReactMarkdown allowDangerousHtml>
                                        {(ruleSet.Notes ? ruleSet.Notes.join('<br/>') : '')}
                                    </ReactMarkdown>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            )}
        </Fragment>
    );
}


export default Rules;